<template>
  <section class="app-content">
    <WorkoutFilter title="Workouts" @apply="loadInitialWorkouts" />
    <section v-if="fetched && !workouts.length">
      No workouts found.
    </section>
    <div v-else-if="workouts.length" class="table-container">
      <table class="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Date</th>
              <th>Distance</th>
              <th>Duration</th>
              <th>Sport</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="workout in workouts" :key="workout.startTime">
              <td>
                <router-link :to="{ name: 'Workout', params: { startTime: workout.startTime} }">
                  <span>{{ formatDate(workout.startTime) }}</span>
                </router-link>
              </td>
              <td><span>{{ formatDistance(workout.distanceMeters) }}</span></td>
              <td><span>{{ formatDuration(workout.durationSeconds) }}</span></td>
              <td><span>{{ getSportById(workout.sport.id).displayName }}</span></td>
              <td><span>{{ workout.name || '&ndash;' }}</span></td>
              <td>
                <router-link
                  :to="{ name: 'Workout', params: {startTime: workout.startTime} }"
                  class="button is-small mr-2"
                >
                  <span class="icon is-small">
                    <font-awesome-icon icon="link"></font-awesome-icon>
                  </span>
                </router-link>
                <a href="#" @click.stop="onDeleteRequested(workout)">
                  <button class="button is-small">
                    <span class="icon is-small">
                      <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </span>
                  </button>
                </a>
              </td>
            </tr>
          </tbody>
      </table>
    </div>
    <div v-if="hasMore" class="has-text-centered">
      <AsyncButton @handle="handleLoadMore">
         Load More
      </AsyncButton>
    </div>
  </section>
  <Modal
    v-if="workoutToDelete"
    title="Please confirm"
    type="warning"
    is-confirm
    is-async
    @dismissed="this.onDeleteModalDismissed()"
    @confirmed="this.onDeleteModalConfirmed()"
  >
    You are about to delete workout from {{ workoutToDelete.startTime }}. Please confirm.
  </Modal>
</template>

<script>
import AsyncButton from '@/components/AsyncButton.vue';
import WorkoutFilter from '@/components/WorkoutFilter.vue';
import Modal from '@/components/Modal.vue';
import { mapGetters } from 'vuex';
import { fromS } from 'hh-mm-ss';

export default {
  name: 'Workouts',
  components: {
    AsyncButton,
    WorkoutFilter,
    Modal,
  },
  data() {
    return {
      workouts: [],
      hasMore: false,
      fetched: false,
      limit: 25,
      workoutToDelete: undefined,
    };
  },
  computed: {
    ...mapGetters('workoutFilter', [
      'filterObject',
    ]),
    ...mapGetters('sport', {
      getSportById: 'getById',
    }),
  },
  async created() {
    this.loadInitialWorkouts();
  },
  methods: {
    async fetchWorkoutsData(filters) {
      this.fetched = false;
      const workoutsData = await this.$api('workout/find', { filters });
      this.fetched = true;

      return workoutsData;
    },
    async loadInitialWorkouts() {
      this.$store.dispatch('workoutFilter/setLoadingStarted', { isCancellable: false });
      const workoutsData = await this.fetchWorkoutsData(this.getFilters());
      this.$store.dispatch('workoutFilter/setLoadingStopped');
      this.workouts = workoutsData.results;
      this.hasMore = workoutsData.hasMore;
    },
    async handleLoadMore() {
      const [{ startTime }] = this.workouts.slice(-1);
      const filters = this.getFilters();
      filters.before = startTime;

      const workoutsData = await this.fetchWorkoutsData(filters);
      workoutsData.results.forEach((workout) => this.workouts.push(workout));
      this.hasMore = workoutsData.hasMore;
    },
    getFilters() {
      const filters = { limit: this.limit };
      Object.assign(filters, this.filterObject);

      return filters;
    },
    formatDuration(seconds) {
      return fromS(seconds);
    },
    formatDistance(meters) {
      const kilometers = (meters / 1000).toFixed(2);
      return `${kilometers} km`;
    },
    formatDate(isoDate) {
      const date = new Date(isoDate);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    onDeleteRequested(workout) {
      this.workoutToDelete = workout;
    },
    onDeleteModalDismissed() {
      this.workoutToDelete = undefined;
    },
    async onDeleteModalConfirmed() {
      try {
        await this.$api('workout/delete', this.workoutToDelete);
        this.$store.dispatch('notification/setSuccess', 'Workout deleted sucessfully');
        this.workouts = this.workouts.filter((workout) => workout !== this.workoutToDelete);
      } catch (error) {
        this.$store.dispatch('notification/setError', error.getMessage());
      }
      this.workoutToDelete = undefined;
    },
  },
};
</script>

<style scoped lang="scss">
  td {
    span {
      vertical-align: middle;
    }
  }
</style>
