<template>
  <section class="app-content is-flex is-flex-direction-column">
    <div class="columns is-flex-tablet is-flex-direction-row is-flex-grow-1">
      <div class="column is-one-quarter">
            <h1 class="title">Workout</h1>
            <div class="workout-detail">
                <p class="label">
                    <font-awesome-icon icon="pen"></font-awesome-icon>
                    Name
                </p>
                <p>
                    {{ workout.name ?? '?' }}
                </p>
            </div>
            <div class="workout-detail">
                <p class="label">
                    <font-awesome-icon icon="calendar"></font-awesome-icon>
                    Date
                </p>
                <p>
                    {{ getFormattedDate() }}
                </p>
            </div>
            <div class="workout-detail">
                <p class="label">
                    <font-awesome-icon icon="ruler"></font-awesome-icon>
                    Distance
                </p>
                <p>
                    {{ getFormattedDistance() }}
                </p>
            </div>
            <div class="workout-detail">
                <p class="label">
                    <font-awesome-icon icon="stopwatch"></font-awesome-icon>
                    Duration
                </p>
                <p>
                    {{ getFormatteduration() }}
                </p>
            </div>
            <div class="workout-detail">
                <p class="label">
                    <font-awesome-icon icon="info"></font-awesome-icon>
                    Sport
                </p>
                <p :style="{ color: getSport()?.color || '#fff'}">
                    {{ getSport()?.displayName || '?' }}
                </p>
            </div>
            <div v-if="getStravaUrl()" class="workout-detail">
                <p class="label">
                    <font-awesome-icon icon="link"></font-awesome-icon>
                    Source
                </p>
                <a
                  :style="{ color: '#FC4C02', fontWeight: 'bold'}"
                  :href="getStravaUrl()"
                  target="_blank">
                    View on Strava
                </a>
            </div>
      </div>
      <div class="column is-flex-grow-1">
        <div id="mapContainer"></div>
      </div>
    </div>
  </section>
</template>

<script>

import cacheMiddleware from '@/services/api/middleware/cache';
import resolveMap from '@/services/map/resolver';
import { mapState } from 'vuex';
import { fromS } from 'hh-mm-ss';

export default {
  name: 'Workout',
  data() {
    return {
      workout: {},
    };
  },
  async mounted() {
    const [workout] = await Promise.all([
      this.$api('workout/get', { startTime: this.$route.params.startTime }, [cacheMiddleware]),
      this.$store.dispatch('sport/getAll'),
    ]);

    if (!workout) {
      const pathSegments = this.$route.path.substring(1).split('/');
      const { query } = this.$route;
      this.$router.push({ name: 'NotFound', params: { pathSegments }, query });
    }

    Object.assign(this.workout, workout);
    await this.initMap();
  },
  async beforeUnmount() {
    this.map.destroy();
  },
  computed: {
    ...mapState('sport', {
      sports: 'items',
    }),
  },
  methods: {
    async initMap() {
      this.map = await resolveMap();
      await this.map.init('mapContainer', 52.06954099, 19.48026829, 6);
      const sport = this.getSport();
      const polyline = this.map.addEncodedPolyline(this.workout.encodedPolyline, sport ? sport.color : '#ffffff');
      this.map.bindViewToPolyline(polyline);
    },
    getFormatteduration() {
      if (!this.workout.durationSeconds) {
        return '?';
      }
      return fromS(this.workout.durationSeconds);
    },
    getFormattedDistance() {
      if (!this.workout.distanceMeters) {
        return '?';
      }
      const kilometers = (this.workout.distanceMeters / 1000).toFixed(2);
      return `${kilometers} km`;
    },
    getFormattedDate() {
      if (!this.workout.startTime) {
        return '?';
      }
      const date = new Date(this.workout.startTime);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    getSport() {
      const sportId = this.workout?.sport?.id;
      return sportId ? this.sports[sportId] : undefined;
    },
    getStravaUrl() {
      if (!this.workout.sourceStravaId) {
        return null;
      }

      return `https://www.strava.com/activities/${this.workout.sourceStravaId}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~bulma';

.app-content {
  @include from($tablet) {
    flex-grow: 1;
  }
}
#mapContainer {
  width: 100%;
  height: 100%;
  min-height:500px;
  z-index: 1;
}

.workout-detail {
    padding: 10px;
}
</style>
